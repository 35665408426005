<template>
  <div class="pa-3 fadeIn" >
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <HeadlineTableView
        :icon="'mdi-post'"
        :headline="'Postfach'"
        :button-icon="''"
        :button-text="''"
    ></HeadlineTableView>
    <v-card flat class="rounded-xl pa-2 pa-sm-8" >
      <v-list>
        <div v-for="notification of notifications" :key="notification.id">
          <v-list-item class="rounded-xl" v-if="notification.read === false"  @click="$router.push('/notifications/notification/'+notification.id)" style="background-color: #F3F4F3">
            <v-list-item-action>
              <v-icon>{{ notification.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title><b>{{ notification.title }}</b></v-list-item-title>
            <v-list-item-title>{{ notification.text }}</v-list-item-title>
            <v-list-item-action-text >
              {{getStringDate( notification.created) }}
            </v-list-item-action-text>
          </v-list-item>

          <v-list-item v-else class="rounded-xl"  @click="$router.push('/notifications/notification/'+notification.id)">
            <v-list-item-action>
              <v-icon>{{ notification.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title><b>{{ notification.title }}</b></v-list-item-title>
            <v-list-item-title>{{ notification.text }}</v-list-item-title>
            <v-list-item-action-text >
              {{getStringDate( notification.created) }}
            </v-list-item-action-text>
          </v-list-item>
        </div>
      </v-list>
    </v-card>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {date2String, msgObj, error} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");
const HeadlineTableView = () => import("@/components/tableView/HeadlineTableView");

export default {
  name: "Notifications",
  components: {
    Message,
    HeadlineTableView
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('notification', {
      notifications: 'notifications',
    }),
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('notification/getNotifications', {
      uid: this.user.id,
      onlyUnread: false
    }).catch((err) => {
      this.message = error(err)
    })
  },
  data: () => ({
    message: msgObj(),
  }),
  methods: {
    getStringDate(date) {
      return date2String(date)
    },
  }
}
</script>

<style lang="sass" scoped>
</style>
<style scoped>
</style>
